import { ConfigFlags } from '../../types/sharedTypes';
import { FETCH_CONFIG, FetchConfigAction, FETCH_LANG_JSON, FetchLanguageJSONAction, FETCH_CONFIG_SHARED } from '../actions/shared/fetchConfig';

export interface ConfigState {
  api: string;
  copyright: string;
  customColor: string;
  customColorsGeneralUser: Record<string, string>;
  languages: Record<string, string>;
  loginPageDisclaimer: string;
  redirectRegistrationURL: string;
  welcomeMessage: string;
  langFile: Record<string, string>;
  flags: ConfigFlags;
  defaultLanguage: string;
  defaultSorting: Record<string, string>;
  currency: Record<string, string>;
  requestTab: boolean;
}

const initialState: ConfigState = {
  api: '',
  copyright: '',
  customColor: '',
  customColorsGeneralUser: {},
  languages: {},
  loginPageDisclaimer: '',
  redirectRegistrationURL: '',
  welcomeMessage: '',
  langFile: {},
  flags: {} as ConfigFlags,
  defaultLanguage: '',
  defaultSorting: {},
  currency: {},
  requestTab: false
};

const configReducer = (state: ConfigState = initialState, action: FetchConfigAction & FetchLanguageJSONAction): ConfigState => {
  switch (action.type) {
    case FETCH_CONFIG:
      return {
        ...state,
        api: action.config.api,
        copyright: action.config.copyright,
        customColor: action.config.customColor,
        customColorsGeneralUser: action.config.customColorsGeneralUser,
        languages: action.config.languages,
        loginPageDisclaimer: action.config.loginPageDisclaimer,
        redirectRegistrationURL: action.config.redirectRegistrationURL,
        welcomeMessage: action.config.welcomeMessage,
        flags: action.config.flags,
        defaultLanguage: action.config.defaultLanguage,
        defaultSorting: action.config.defaultSorting,
        currency: action.config.currency
      };
    case FETCH_LANG_JSON:
      return {
        ...state,
        langFile: action.langFile
      }
    case FETCH_CONFIG_SHARED:
      return {
        ...state, 
        requestTab: action.config.RequestTab
      }
    default:
      return state;
  }
}

export default configReducer;
